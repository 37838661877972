export const colorPalettes = {
  "/": {
    activeLink: "var(--grey)",
    firstName: "var(--oatmeal)",
    firstNameHover: "var(--lightred)",
    slash: "var(--blue)",
    slashHover: "var(--oatmeal)",
    lastName: "var(--lightred)",
    lastNameHover: "var(--blue)",
  },
  "/projects": {
    activeLink: "var(--lightred)",
    firstName: "var(--white)",
    firstNameHover: "var(--lightred)",
    slash: "var(--lightred)",
    slashHover: "var(--white)",
    lastName: "var(--white)",
    lastNameHover: "var(--lightred)",
  },
  "/blog": {
    activeLink: "var(--blue)",
    firstName: "var(--oatmeal)",
    firstNameHover: "var(--blue)",
    slash: "var(--blue)",
    slashHover: "var(--oatmeal)",
    lastName: "var(--oatmeal)",
    lastNameHover: "var(--blue)",
  },
  "/about": {
    activeLink: "var(--oatmeal)",
    firstName: "var(--lightred)",
    firstNameHover: "var(--oatmeal)",
    slash: "var(--oatmeal)",
    slashHover: "var(--lightred)",
    lastName: "var(--lightred)",
    lastNameHover: "var(--oatmeal)",
  },
  "/contact": {
    activeLink: "var(--white)",
    firstName: "var(--blue)",
    firstNameHover: "var(--white)",
    slash: "var(--white)",
    slashHover: "var(--blue)",
    lastName: "var(--blue)",
    lastNameHover: "var(--white)",
  },
}
//   {
//     path: "/",
//     activeLink: "var(--grey)",
//     firstName: "var(--oatmeal)",
//     firstNameHover: "var(--lightred)",
//     slash: "var(--blue)",
//     slashHover: "var(--oatmeal)",
//     lastName: "var(--lightred)",
//     lastNameHover: "var(--blue)",
//   },
//   {
//     path: "/projects",
//     activeLink: "var(--lightred)",
//     firstName: "var(--white)",
//     firstNameHover: "var(--lightred)",
//     slash: "var(--lightred)",
//     slashHover: "var(--white)",
//     lastName: "var(--white)",
//     lastNameHover: "var(--lightred)",
//   },
//   {
//     path: "/blog",
//     activeLink: "var(--blue)",
//     firstName: "var(--oatmeal)",
//     firstNameHover: "var(--blue)",
//     slash: "var(--blue)",
//     slashHover: "var(--oatmeal)",
//     lastName: "var(--oatmeal)",
//     lastNameHover: "var(--blue)",
//   },
//   {
//     path: "/about",
//     activeLink: "var(--oatmeal)",
//     firstName: "var(--lightred)",
//     firstNameHover: "var(--oatmeal)",
//     slash: "var(--oatmeal)",
//     slashHover: "var(--lightred)",
//     lastName: "var(--lightred)",
//     lastNameHover: "var(--oatmeal)",
//   },
//   {
//     path: "/contact",
//     activeLink: "var(--white)",
//     firstName: "var(--blue)",
//     firstNameHover: "var(--white)",
//     slash: "var(--white)",
//     slashHover: "var(--blue)",
//     lastName: "var(--blue)",
//     lastNameHover: "var(--white)",
//   },
// ]
